<template>
  <list-container-widget
    id="tasks"
    :app-id="2"
    :view-more-location="getLocation('tasks')"
    :disable-view-more="disableViewMore"
    :size="addOnSize"
    :stretch="false"
  >
    <template #actions>
      <!-- Panel actions -->
      <ViewAllButton item-type="tasks" />
    </template>
    <template>
      <div
        v-if="areFiltersVisible"
        class="flex-column flex-md-row justify-content-center filters-container mb-2 px-50 pt-1"
      >
        <!-- Classifiers -->
        <div
          class="d-flex flex-column flex-md-row filters-container mb-2 px-50 pt-1"
        >
          <b-form-group class="mr-1 w-100">
            <label for="classifier-filter">{{ $t("filterType") }}</label>
            <v-select
              v-model="selectedClassifiersForFilter"
              name="classifier-filter"
              clearable
              label="name"
              :reduce="(a) => a"
              :options="finalOptions"
              @input="setSeletedFilter"
              style="height = 38px"
            />
          </b-form-group>
          <!-- End Classifiers -->

          <!-- Custom -->
          <b-form-group v-if="nextFilter != null" class="mr-1 w-100">
            <label
              v-if="nextFilter.type || nextFilter[0] != null"
              for="custom-filter"
              >Value</label
            >
            <v-select
              v-if="nextFilter[0] != null"
              v-model="selectedCustomFieldsForFilter"
              name="custom-filter"
              clearable
              label="name"
              :reduce="(nextFilter) => nextFilter"
              :options="nextFilter"
              @input="setSeletedFinalFilter"
            />

            <b-form-input
              v-if="nextFilter.type === 2"
              v-model="customFieldToSend[nextFilter.key]"
              placeholder="Start typing..."
              class="w-100"
              style="height: 33.18px"
              @keyup="handleChanger(null)"
              @input="
                setSeletedFinalFilter({
                  text: $event,
                  type: 2,
                  key: nextFilter.key,
                })
              "
            />
          </b-form-group>
          <!-- End Custom -->
        </div>
      </div>
    </template>
    <div v-if="isLoading" class="mb-5">
      <b-spinner
        type="grow"
        small
        variant="primary"
        class="mx-auto mt-3 d-block"
      />
    </div>
    <div v-else-if="suggestions.length > 0">
      <b-row class="todo-app-list mt-3">
        <b-col>
          <b-table
            class="position-relative"
            :items="suggestions"
            responsive
            :fields="fields"
            primary-key="id"
            show-empty
            empty-text="loading"
          >
            <!-- Column: Title -->
            <template #cell(title)="{ item }">
              <b-media class="d-flex align-items-center">
                <b-link
                  :to="getSuggestionLocation(item)"
                  class="text-dark d-flex mt-50"
                >
                  <p class="mb-0 ml-1 pl-25 truncated-text">
                    {{ item.title }}
                  </p>
                </b-link>
              </b-media>
            </template>
            <!-- Column: Suggestion -->
            <template #cell(suggestion)="{ item }">
              <b-link
                :to="getSuggestionLocation(item)"
                class="text-dark d-flex"
              >
                <div>
                  <p class="mb-0 ml-1 pl-25 truncated-text">
                    {{ item.message }}
                  </p>
                </div>
              </b-link>
            </template>

            <!-- Column: User -->
            <template #cell(user)="{ item }">
              <b-link
                :to="getSuggestionLocation(item)"
                class="text-dark d-flex"
              >
                <div v-if="item.assignee" class="text-nowrap">
                  <p class="mb-0">
                    <b-avatar
                      v-b-tooltip.hover
                      :title="`${item.assignee.name} ${item.assignee.surname} `"
                      size="40"
                      :src="getImageSrc(item.assignee.avatarURLl)"
                      class="mx-1"
                    />
                    {{ item.assignee.name }}
                    {{ item.assignee.surname }}
                  </p>
                </div>
                <span v-else class="text-muted ml-2 mt-50">---</span>
              </b-link>
            </template>
            <!-- Column: Priority -->
            <template #cell(priority)="{ item }">
              <b-link
                :to="getSuggestionLocation(item)"
                class="text-dark d-flex"
              >
                <b-icon
                  v-b-modal.modal-edit-priority
                  icon="flag-fill"
                  scale="1.3"
                  :variant="resolveTagVariant(item.priority)"
                  class="mt-1 cursor-pointer ml-2"
                  style="outline-style: none"
                ></b-icon>
                <span class="mt-50 ml-50"
                  ><small>{{
                    getTranslatedPriority(item.priority)
                  }}</small></span
                >
              </b-link>
            </template>
            <!-- Column: Status -->
            <template #cell(status)="{ item }">
              <b-link
                :to="getSuggestionLocation(item)"
                class="text-dark d-flex mt-50"
              >
                <div class="text-nowrap">
                  <p class="mb-0">
                    <b-badge
                      pill
                      :variant="`${resolveStatusVariant(item.status)}`"
                      class="text-capitalize ml-1"
                    >
                      {{ getStatus(item.status) }}
                    </b-badge>
                  </p>
                </div>
              </b-link>
            </template>
            <!-- Column: OwnedByMember -->
            <template #cell(creator)="{ item }">
              <b-link
                :to="getSuggestionLocation(item)"
                class="text-dark d-flex mt-50"
              >
                <span v-if="item.ownedByMember" class="ml-2"
                  >{{ item.ownedByMember.name }}
                  {{ item.ownedByMember.surname }}</span
                >
                <span v-else class="text-muted ml-2">---</span>
              </b-link>
            </template>
            <!-- Column: Due date -->
            <template #cell(date)="{ item }">
              <b-link
                :to="getSuggestionLocation(item)"
                class="text-dark d-flex mt-50"
              >
                <span v-if="item.dueDate" class="ml-2">{{
                  new Date(item.dueDate).toLocaleDateString()
                }}</span>
                <span v-else class="text-muted ml-2">---</span>
              </b-link>
            </template>
            <!-- Column:  Attach -->
            <template #cell(attach)="{ item }">
              <b-link
                :to="getSuggestionLocation(item)"
                class="text-dark d-flex mt-50"
              >
                <feather-icon
                  v-if="item.attachments && item.attachments.length > 0"
                  icon="PaperclipIcon"
                  size="16"
                  class="text-muted"
                />
              </b-link>
            </template>
          </b-table>
          <div class="d-flex justify-content-center">
            <b-pagination
              v-model="page"
              :total-rows="total"
              :per-page="8"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-col>
      </b-row>
    </div>
    <!-- Container without data: Placeholder -->
    <b-row v-else class="horizontal-placeholder">
      <b-col cols="12">
        <img :src="placeholder" />
      </b-col>
      <b-col cols="12">
        <p class="text-primary">
          {{ $t("available.message", { itemName: $t("tasks.title") }) }}
        </p>
      </b-col>
    </b-row>
  </list-container-widget>
</template>

<script>
import { VBTooltip } from "bootstrap-vue";
import WidgetLayoutMixin from "@/@core/mixins/widgets/WidgetLayoutMixin";
import ListContainerWidget from "@core/widgets/ListContainerWidget.vue";
// import TasksTable from '@/views/apps/tasks/components/TasksTable.vue';
// import ToastNotificationsMixin from "@core/mixins/toast-notifications/ToastNotificationsMixin";
// import WidgetActions from "@/@core/components/widget-actions/WidgetActions.vue";
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
// import OrderBy from "@core/constants/OrderBy";
// import vSelect from "vue-select";
import { getImageResource } from "@/@core/utils/image-utils";
import Placeholder from "@/assets/images/placeholders/light/general-placeholder.svg";
// import { quillEditor } from "vue-quill-editor";
// import FileTypes from "@/@core/constants/FileTypes";
// import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
// import FileUpload from "@core/components/files/FileUpload.vue";
import { checkPermissions } from "@/@core/utils/roles-utils";
// import MembersSelect from "@/views/apps/member/components/MembersSelect.vue";

export default {
  name: "TareasListWidget",
  components: {
    ListContainerWidget,
    // quillEditor,
    // WidgetActions,
    // vSelect,
    // MembersSelect,
    ViewAllButton: () => import ("@/@core/components/widget-actions/ViewAllButton.vue"  /* webpackChunkName: "ViewAllButton" */),
  },
  directives: { BTooltip: VBTooltip },
  mixins: [WidgetLayoutMixin],
  props: {
    perPage: {
      type: Number,
      default: 8,
    },
    disableViewMore: Boolean,
    organizationKey: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      areFiltersVisible: false,
      filtersIndex: 0,
      filters: [],
      parentClassifiers: [],
      nextFilter: [],
      selectedClassifiersForFilter: [],
      selectedCustomFieldsForFilter: [],
      customFieldsInputs: [],
      customFieldToSend: {},
      index: 0,
      timer: null,
      searchInput: "",
      results: [],
      finalTypeSelected: null,
      finalSelectClassifier: [],
      currentPage: 1,
      showfilter: false,
      isSomeFilterChanged: false,
      projectInput: {},
      imageProject: null,
      imageSrc: null,
      page: 1,
      dueDate: null,
      status: "",
      priority: "",
      assignee: "",
      type: "",
      attach: null,
    };
  },
  computed: {
    priorityOptions() {
      return [
        { label: this.$t("tasks.priority.urgent"), code: 1 },
        { label: this.$t("tasks.priority.high"), code: 2 },
        { label: this.$t("tasks.priority.normal"), code: 3 },
        { label: this.$t("tasks.priority.low"), code: 4 },
      ];
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    typeOptions() {
      return [
        { name: this.$t("backoffice.feedback.status.to-do"), code: 42615 },
        {
          name: this.$t("backoffice.feedback.status.in-progress"),
          code: 42616,
        },
        { name: this.$t("backoffice.feedback.status.done"), code: 42617 },
        { name: this.$t("backoffice.feedback.status.rejected"), code: 42618 },
      ];
    },
    isStaff() {
      return this.collective.isStaff;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
    canAdd() {
      return checkPermissions(
        "create",
        "task",
        this.loggedMemberRoles,
        this.collective
      );
    },
    taskFilters() {
      return [
        {
          color: "danger",
          title: this.$t("backoffice.feedback.type-options.bug"),
        },
        {
          color: "warning",
          title: this.$t("backoffice.feedback.type-options.improvement"),
        },
        {
          color: "info",
          title: this.$t("backoffice.feedback.type-options.query"),
        },
      ];
    },
    taskStatus() {
      return [
        { icon: "ListIcon", title: this.$t("backoffice.feedback.status.all") },
        {
          icon: "InboxIcon",
          title: this.$t("backoffice.feedback.status.to-do"),
        },
        {
          icon: "EditIcon",
          title: this.$t("backoffice.feedback.status.in-progress"),
        },
        {
          icon: "EyeIcon",
          title: this.$t("backoffice.feedback.status.in-review"),
        },
        {
          icon: "CheckSquareIcon",
          title: this.$t("backoffice.feedback.status.done"),
        },
        {
          icon: "ForbiddenIcon",
          title: this.$t("backoffice.feedback.status.rejected"),
        },
      ];
    },
    fields() {
      return [
        {
          key: "title",
          label: this.$t("tasks.name"),
        },
        {
          key: "user",
          label: this.$t("tasks.status.assignee"),
        },
        {
          key: "priority",
          label: this.$t("tasks.status.priority"),
        },
        {
          key: "status",
          label: this.$t("backoffice.feedback.status-title"),
        },
        {
          key: "creator",
          label: this.$t("contacts.creator"),
        },
        {
          key: "date",
          label: this.$t("tasks.status.due-date"),
        },
        {
          key: "attach",
          label: "",
        },
      ];
    },
    itemsData() {
      return this.$store.getters.tasks.unpaginated;
    },
    suggestions() {
      if (this.itemsData) {
        const itemsToIterate = this.searchInput ? this.results : this.itemsData;
        return itemsToIterate;
      }
      return [];
    },
    // perfectScrollbarSettings() {
    //   return { maxScrollbarLength: 150 };
    // },
    placeholder() {
      return Placeholder;
    },
    item() {
      return this.$t("tasks.title");
    },
    types() {
      return this.$store.getters.types.member?.unpaginated;
    },
    total() {
      return this.$store.getters.tasks?.meta?.total;
    },
    classifiers() {
      if (this.$store.getters.classifiers.members) {
        return this.$store.getters.classifiers.members.unpaginated;
      }
      return [];
    },
    customFields() {
      return this.$store.getters.customFields.unpaginated ?? [];
    },
    customFieldsOptions() {
      const customName = [];
      try {
        for (const row of this.customFields) {
          customName.push({
            name: translateTranslationTable(
              this.$store.state.locale.currentLocale,
              row.name
            ),
            key: row.key,
            type: 2,
          });
        }
        return customName;
      } catch (e) {
        return customName;
      }
    },
    sortedCommunityClassifiers() {
      const newObject = {};
      for (const item of this.classifiers) {
        if (
          !newObject[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              item.typeName
            )
          ]
        ) {
          newObject[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              item.typeName
            )
          ] = [item];
        } else {
          newObject[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              item.typeName
            )
          ].push(item);
        }
      }

      const finalKeys = [];

      const keys = Object.keys(newObject);
      for (const row of keys) {
        finalKeys.push({
          key: row,
          name: row,
          type: 1,
        });
      }

      for (const item of this.types) {
        if (item != null) {
          newObject["types"] = { name: "Types" };
          finalKeys.push({
            key: "types",
            name: "Types",
            type: 3,
          });
          break;
        }
      }
      return [newObject, finalKeys];
    },
    finalOptions() {
      return [].concat(
        this.sortedCommunityClassifiers[1],
        this.customFieldsOptions
      );
    },
  },
  watch: {
    imageProject(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.base64Encode(newValue)
            .then((value) => {
              this.imageSrc = value;
            })
            .catch(() => {
              this.imageSrc = null;
            });
        } else {
          this.imageSrc = null;
        }
      }
    },
    page(page) {
      this.fetchData(page);
    },
  },
  async created() {
    this.isLoading = true;
    await this.fetchData(1);
    this.type = this.typeOptions[0];
    // await this.fetchClassifiers();
    // await this.fetchTypes();
    // await this.fetchCustomfields();
    this.isLoading = false;
  },
  methods: {
    reset() {
      this.attach = null;
      this.projectInput = {};
      this.dueDate = null;
      this.status = "";
      this.priority = "";
      this.assignee = "";
      this.type = "";
    },
    base64Encode(data) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    searchItems(value) {
      this.searchInput = value;
      const searchTerm = value.toLowerCase().trim();
      if (!searchTerm) {
        return;
      }
      const searchTermToLower = searchTerm.toLowerCase();
      this.results = this.itemsData.unpaginated.filter((item) => {
        const itemNameStr = JSON.stringify(item.name).toLowerCase();
        if(itemNameStr.includes(searchTermToLower)){
          return item;
        }
      });
    },
    resolveTagVariant(tag) {
      if (tag === 1) {
        return "danger";
      }
      if (tag === 2) {
        return "warning";
      }
      if (tag === 3) {
        return "info";
      }
      if (tag === 4) {
        return "muted";
      }
      return "light";
    },
    async fetchMembers(page) {
      return this.$store.dispatch("getItems", {
        itemType: "communityMembers",
        forceAPICall: true,
        page,
        perPage: 16,
        ...(this.$store.getters.currentCollective?.parentCollective?.slug &&
        this.$store.getters.currentCollective?.isSection
          ? {
              communitySlug:
                this.$store.getters.currentCollective?.parentCollective?.slug,
            }
          : ""),
      });
    },
    getImageSrc(url) {
      return getImageResource(url);
    },
    async handleCreate() {
      if (!this.projectInput.title) {
        return;
      }
      try {
        await this.$store.dispatch("createItem", {
          item: {
            itemType: "tasks",
            requestConfig: {
              title: this.projectInput.title,
              description: this.projectInput?.description,
              dueDate: new Date(this.dueDate).toISOString(),
              assignee: this.assignee?.key,
              priorityID: this.priority?.code,
              statusID: this.type?.code,
            },
          },
          file: this.attach,
        });
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t("tasks.messages.create-success"),
            icon: "CheckIcon",
            variant: "success",
          },
        });
        this.projectInput = {};
        this.status = "";
        this.priority = "";
        this.assignee = "";
        this.type = "";
        this.imageProject = null;
      } catch {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t("tasks.messages.create-error"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    async fetchTypes() {
      await this.$store.dispatch("getItems", {
        itemType: "types",
        storedKey: "member",
        forceAPICall: true,
        requestConfig: {
          modelType: "member",
          onlyUsedTypes: 1,
        },
      });
      /*await this.$store.dispatch('getItems', {
        itemType: 'communityMembersByType',
        forceAPICall: true,
        requestConfig: {
          onlyUsedTypes: 1
        },
      });*/
    },
    async fetchCustomfields() {
      await this.$store.dispatch("getItems", {
        itemType: "customField",
        customName: "customFields",
        page: 1,
        requestConfig: {
          morphType: "member",
        },
      });
      this.canFilter();
    },
    canFilter() {
      if (this.classifiers.length > 0 || this.customFields.length > 0) {
        this.showfilter = true;
      } else {
        this.showfilter = false;
      }
    },
    formatDate(date) {
      const d = new Date(date);
      return d.toLocaleString("en-GB");
    },
    async fetchData(page) {
      this.isLoading = true;
      await this.$store.dispatch("getItems", {
        itemType: "tasks",
        forceAPICall: true,
        page,
        perPage: 8,
      });
      this.isLoading = false;
    },
    getSuggestionLocation(task) {
      return {
        name: "task-details",
        params: { id: task.key, communityId: this.$route.params.communityId },
      };
    },
    getTypeText(tag) {
      return tag === "bug"
        ? this.$t("backoffice.feedback.type-options.bug")
        : tag === "feature_improvement"
        ? this.$t("backoffice.feedback.type-options.improvement")
        : this.$t("backoffice.feedback.type-options.query");
    },
    handleFilter(filter) {
      if (filter.title === this.$t("backoffice.feedback.status.all")) {
        this.suggestions = this.$store.getters.feedback;
      }
      if (filter.title === this.$t("backoffice.feedback.status.to-do")) {
        this.suggestions = this.suggestions.filter(
          (item) => item.status === "To do"
        );
      }
      if (filter.title === this.$t("backoffice.feedback.status.in-review")) {
        this.suggestions = this.suggestions.filter(
          (item) => item.status === "In review"
        );
      }
      if (filter.title === this.$t("backoffice.feedback.status.rejected")) {
        this.suggestions = this.suggestions.filter(
          (item) => item.status === "Rejected"
        );
      }
    },
    resolveStatusVariant(status) {
      if (status === "To do" || status === null) {
        return "secondary";
      }
      if (status === "In review") {
        return "primary";
      }
      if (status === "In progress") {
        return "warning";
      }
      if (status === "Rejected") {
        return "danger";
      }
      return "success";
    },

    getStatus(status) {
      const statusTranslated = {
        "To do": this.$t("backoffice.feedback.status.to-do"),
        null: this.$t("backoffice.feedback.status.to-do"),
        "In progress": this.$t("backoffice.feedback.status.in-progress"),
        "In review": this.$t("backoffice.feedback.status.in-review"),
        Rejected: this.$t("backoffice.feedback.status.rejected"),
        Done: this.$t("backoffice.feedback.status.done"),
      };
      return statusTranslated[status];
    },
    async fetchClassifiers() {
      await this.$store.dispatch("getItems", {
        itemType: "classifiers",
        storedKey: "members",
        page: 1,
        requestConfig: { morphType: "members", count: 1000 },
      });
      this.canFilter();
      this.isLoading = false;
    },
    handleChanger(search = null) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.fetchData({ page: this.currentPage, forceAPICall: true });
      }, 800);
    },
    toggleFilters() {
      this.areFiltersVisible = !this.areFiltersVisible;
      if (!this.areFiltersVisible) {
        this.customFieldToSend = {};
        this.finalSelectClassifier = [];
        this.nextFilter = null;
        this.finalTypeSelected = null;
        this.selectedCustomFieldsForFilter = null;
        this.filtersIndex = 0;
        this.filters = [];
        this.parentClassifiers = [];
        this.selectedClassifiersForFilter = [];
        this.customFieldsInputs = [];
        this.index = 0;
        if (this.isSomeFilterChanged) {
          this.fetchData({ page: 1, forceAPICall: true });
        }
      }
    },
    async setSeletedFilter(selected) {
      this.CustomFieldToSend = {};
      this.finalSelectClassifier = null;
      this.selectedCustomFieldsForFilter = null;
      this.nextFilter = null;
      if (!selected || selected.lenght === 0) {
        this.finalSelectClassifier = null;
      } else {
        let translated = [];
        if (selected.type === 1) {
          this.selectedClassifiersForFilter = selected.key;
          for (const row of this.sortedCommunityClassifiers[0][selected.key]) {
            translated.push({
              name: translateTranslationTable(
                this.$store.state.locale.currentLocale,
                row.name
              ),
              key: row.key,
              type: 1,
            });
          }
        } else if (selected.type === 2) {
          this.customFieldToSend[selected.key] = "";
          translated = { type: 2, key: selected.key };
        } else if (selected.type === 3) {
          for (const item of this.types) {
            if (item == null) {
              continue;
            }
            translated.push({
              //name: translateTranslationTable(this.$store.state.locale.currentLocale, item.typePlural),
              //key: item.typeKey,
              name: translateTranslationTable(
                this.$store.state.locale.currentLocale,
                item.namePlural
              ),
              key: item.key,
              type: 3,
            });
          }
        }
        this.nextFilter = translated;
      }
    },
    async setSeletedFinalFilter(selected) {
      if (selected) {
        if (selected.type === 2) {
          this.customFieldToSend[selected.key] = selected.text;
        } else if (selected.type === 1) {
          this.finalSelectClassifier = selected.key;
        } else if (selected.type === 3) {
          this.finalTypeSelected = selected.key;
        }
        this.isSomeFilterChanged = true;
        await this.handleChanger();
      } else {
        this.finalSelectClassifier = null;
        await this.handleChanger();
      }
    },
    async handleLoadOfNewItems({ page }) {
      if (!this.isLoading && this.suggestions.length <= this.total) {
        await this.fetchData({ page, forceAPICall: true });
      }
    },
    getTranslatedPriority(item) {
      return this.priorityOptions.filter(
        (priority) => priority.code === item
      )[0]?.label;
    },
    getImageResource,
  },
};
</script>

<style lang="scss" scoped>
.truncated-text {
  white-space: nowrap;
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 250px;
  min-width: 0;
}
</style>
